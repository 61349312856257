import React from "react"
import { graphql } from "gatsby"
import CakeShopTemplate from "../components/order-cake-online-template"

const CakeShopPage = ({ data }) => (
  <CakeShopTemplate
    title={data.markdownRemark.frontmatter.title}
    seoTitle={data.markdownRemark.frontmatter.seoTitle}
    rows={data.markdownRemark.frontmatter.rows}
    banner={data.markdownRemark.frontmatter.banner.childImageSharp.fluid}
    description={data.markdownRemark.frontmatter.description}
    metaDescription={data.markdownRemark.frontmatter.metaDescription}
  />
)

export default CakeShopPage
export const query = graphql`
  query CakeShop($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rows {
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
          buttonURL
          buttonText
        }
        title
        seoTitle
        description
        metaDescription
      }
    }
  }
`
